
//home-page    
.page-container
    min-height: 100vh
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

.maintitle
    font-family: $head-font
    text-transform: uppercase
    font-size: 40px
    margin-bottom: 0

.maintitle-header
    position: relative
    span
        font-family: $body-font
        font-weight: 300
        font-size: 12px
        text-transform: lowercase
        position: absolute
        bottom: 80%
        left: 101%
.overlay
    position: absolute
    top: 0
    left: 0
    color: $pink
    transition: width 0.5s ease-out

.btn-basic
    display: flex
    justify-content: center
    align-items: center
    font-family: $body-font
    font-size: 20px
    color: white
    min-height: 50px
    min-width: 160px
    text-align: center
    padding: 10px
    background-color: rgba(0,0,0,0)
    border: solid 2px white
    border-radius: 25px
    -webkit-box-shadow: 0px 0px 8px 1px rgba(255,255,255,1)
    -moz-box-shadow: 0px 0px 8px 1px rgba(255,255,255,1)
    box-shadow: 0px 0px 4px 1px rgba(255,255,255,1)
    text-shadow: 0px 0px 4px #FFFFFF
    cursor: pointer

.difficulty-container 
    position: relative   
.difficulty
    display: flex
    flex-direction: column
    margin-top: 20px
    


.btn-difficulty
    margin: 10px 20px
    transition: background-color 0.3s ease-out

.active-level
    background-color: $pink
.btn-play 
    position: absolute
    bottom: -90px
    right: 0
    left: 0
    margin: auto


//game page

.game-container
    position: relative
    overflow-x: hidden 
//gameplay

.container
    display: flex
    position: relative
    flex-direction: column
    justify-content: center
    align-items: center
    height: 280px
    width: 100%

.color-title
    font-weight: 300
    text-align: center
    font-size: 35px 
    margin-top: 0
.btn-new
    position: absolute
    bottom: 20px
    left: 20px

//gameplay component

.gameplay-container
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    

.message-container
    min-height: 70px
.message
    color: $pink
    font-size: 25px
    margin-bottom: 5px
    margin-top: 15px

.circle-container
    display: flex
    flex-wrap: wrap
    justify-content: space-around
    
.circle
    height: 100px
    width: 100px
    border-radius: 50%
    background-color: $light-grey
    margin: 18px
    margin-bottom: 0
    cursor: pointer
    transition: scale 0.3s ease-out
.disappear
    scale: 0
.btn-reset
    color:  $light-grey
    border: solid 2px  rgba($light-grey, 0.7)
    margin-top: 20px

//popup

.popup-background
    background-color: rgba(255, 255, 255, 0.7)
    position: absolute
    z-index: 10
    top: 0
    left: 0
    bottom: 0
    right: 0
    display: flex
    justify-content: center
    align-items: center

.popup-container
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    background-color: $blue
    border-radius: 25px
    padding: 20px
    margin: 15px

.popup-btn-container
    display: flex
    justify-content: center
    flex-wrap: wrap
    margin-bottom: 10px
    

.btn-exit
    background-color: rgba(0,0,0,0)
    font-family: $body-font
    color: white
    font-size: 30px
    border: none
    cursor: pointer  
    float: right
    margin-right: 10px

.popup-message
    margin-top: 35px
    margin-bottom: 5px
    font-size: 20px
    text-align: center
.btn-popup
    margin: 10px

// winner
.winner-container
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

.winner-btn-container
    display: flex
    button
        margin: 20px
@media screen and (min-width: 600px)
    .maintitle
        font-size: 80px
    .difficulty
        flex-direction: row
    .maintitle-header
        font-size: 60px
        span
            font-size: 20px
    .color-title
        font-size: 45px 
    .circle
        height: 120px
        width: 120px
    .popup-btn-container
        width: 400px