* 
    padding: 0
    margin: 0
    font-family: $body-font
    color: white
    box-sizing: border-box

a
    text-decoration: none

ul 
    list-style: none
    padding: 0
    margin: 0

.gradient
    background: #77A1D3  // fallback for old browsers 
    background: -webkit-linear-gradient(to right, #E684AE, #79CBCA, #77A1D3)  // Chrome 10-25, Safari 5.1-6 
    background: linear-gradient(to right, #E684AE, #79CBCA, #77A1D3) // W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+


